<template>
  <div class="login-body">
    <div class="login-container">
      <div class="head">
        <div class="name">
          <div class="title">设备在线报修系统</div>
          <!-- <div class="tips">正式使用请登陆正式系统，此系统仅供测试使用</div> -->
        </div>
      </div>
      <el-form label-position="top" :rules="rules" :model="ruleForm" ref="loginForm" class="login-form" @keyup.enter.native="submitForm">
        <el-form-item label="账号" prop="username">
          <el-input type="text" v-model.trim="ruleForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model.trim="ruleForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" >
          <div style="display: flex;align-items: center;">
            <el-input type="text" v-model.trim="ruleForm.code" placeholder="请输入验证码"></el-input>
            <el-image :src="ruleForm.src" @click="getKey" style="height: 26px;width: 117px" />
          </div>
        </el-form-item>
        <el-form-item>
          <div style="color: #333">登录表示您已同意<a>《服务条款》</a></div>
          <el-button style="width: 100%" type="primary" @click="submitForm">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted } from 'vue'

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'
import VueCookies from 'vue-cookies'
import { useRouter } from 'vue-router'

var key = ""
var key_create_time = 0

export default {
  name: 'Login',
  setup() {
    const $router = useRouter()
    const loginForm = ref(null)
    const state = reactive({
      ruleForm: {
        username: '',
        password: '',
        code:"",
        src:"",
      },
      rules: {
        username: [
          { required: 'true', message: '账户不能为空', trigger: 'blur' }
        ],
        password: [
          { required: 'true', message: '密码不能为空', trigger: 'blur' }
        ],
        code: [
          { required: 'true', message: '验证码不能为空', trigger: 'blur' }
        ]
      }
    })
    const submitForm = async () => {

      if (new Date().getTime() - key_create_time > 1000 * 60 * 5 ){
        ElMessage.error('验证码已经过期，请重新输入')
        state.ruleForm.code = ""
        getKey()
        return false;
      }
      loginForm.value.validate((valid) => {
        if (valid) {
          axios.post('/User/Login', {
            username: state.ruleForm.username || '',
            password: state.ruleForm.password,
            code: state.ruleForm.code,
            key: key,
          }).then((user_data) => {
            if (user_data.code == 0) {
              // 获取权限
              axios.get("/RoleAuthority/List?size=1000&role_id=" + user_data.data
                  .role_id, {headers: {'token': user_data.data.token}}).then((data) => {
                var RoleAuthority = []
                for (const v of data.data.list) {
                  RoleAuthority.push(v.tag)
                }

                // 保存权限信息到本地存储
                window.localStorage.setItem("YXV0aG9yaXR5", window
                    .btoa(JSON.stringify(RoleAuthority)))

                // 保存token到cookie
                VueCookies.set("token",user_data.data.token, 60 * 60 * 2)

                ElMessage.success('登录成功')
                window.location.href = '/Welcome';
              })
            }  else  {
              ElMessage.success(user_data.msg)
            }
          })
        } else {
          console.log('error submit!!')
          return false;
        }
      })
    }

    const getKey = () => {
      axios.get('/User/Captcha').then((data) => {
        state.ruleForm.src = "data:image/jpeg;base64,"+data.data.base64
        key = data.data.validateKey
        key_create_time = new Date().getTime()
      })
    }

    onMounted(() => {
      getKey()
    });


    const resetForm = () => {
      loginForm.value.resetFields();
    }
    return {
      ...toRefs(state),
      loginForm,
      onMounted,
      getKey,
      submitForm,
      resetForm
    }
  }
}
</script>

<style scoped>
.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*background-color: #fff;*/
  background-image: linear-gradient(25deg, #00433c, #007066, #009688, #00b8a7);
}
.login-container {
  width: 420px;
  height: 520px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 21px 41px 0px rgba(0, 0, 0, 0.2);
}
.head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 20px 0;
}
.head img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.head .title {
  font-size: 28px;
  color: #1BAEAE;
  font-weight: bold;
}
.head .tips {
  font-size: 12px;
  color: #999;
}
.login-form {
  width: 70%;
  margin: 0 auto;
}
</style>
<style>
.el-form--label-top .el-form-item__label {
  padding: 0;
}
.login-form .el-form-item {
  margin-bottom: 12px;
}
</style>